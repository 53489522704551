import React, { useState, useEffect, useLayoutEffect, useContext } from "react"
import Layout from "../../components/layout"
import gql from "graphql-tag"
import { useLazyQuery } from "@apollo/react-hooks"

import { Container, Section } from "../../components/anti/grid/grid"
import { Button } from "../../components/anti/buttons/buttons"
import { Card } from "../../components/anti/card/card"

import icSearch from "../../assets/img/icons/ic-search.png"

import { LangContext } from "../../context/LangContext"

const Search_Pages = gql`
  query Search(
    $key: String
    $first: Int
    $last: Int
    $after: String
    $before: String
  ) {
    contentNodes(
      where: { search: $key, contentTypes: [PAGE, POST, PROJECT], language: EN }
      first: $first
      last: $last
      after: $after
      before: $before
    ) {
      edges {
        cursor
        node {
          ... on Post {
            translation(language: ID) {
              id
              slug
              title
              excerpt
            }
            id
            slug
            title
            excerpt
            language {
              name
            }
          }
          ... on Project {
            id
            slug
            title
            language {
              name
            }
            propertyTypes {
              nodes {
                name
                slug
              }
            }
            blocks {
              ... on AcfColHeadlineBlock {
                colHeadline {
                  title
                  text
                }
              }
            }
            translation(language: ID) {
              id
              slug
              title
              propertyTypes {
                nodes {
                  name
                  slug
                }
              }
              blocks {
                name
                ... on AcfColHeadlineBlock {
                  colHeadline {
                    title
                    text
                  }
                }
              }
            }
          }
          ... on Page {
            id
            title
            slug
            excerpt
            language {
              name
            }
            translation(language: ID) {
              id
              title
              slug
              excerpt
            }
          }
          ... on Facility {
            id
            title
            slug
            content
            language {
              name
            }
            facility_categories {
              nodes {
                slug
              }
            }
            translation(language: ID) {
              id
              title
              slug
              content
              facility_categories {
                nodes {
                  slug
                }
              }
            }
          }
        }
      }
      pageInfo {
        endCursor
        startCursor
        hasPreviousPage
        hasNextPage
      }
    }
  }
`

const SearchResult = ({ location }) => {
  const { lang, setLang } = useContext(LangContext)

  const [getResult, { data: searchResult, loading }] = useLazyQuery(
    Search_Pages
  )

  useLayoutEffect(() => {
    const navbar = document.querySelector(".navbar")
    navbar.classList.remove("transparent")
    navbar.classList.replace("dark", "light")
  }, [])

  useEffect(() => {
    if (location.search.split("?key=").length > 1) {
      getResult({
        variables: {
          key:
            location.search.split("?key=").length > 1
              ? location.search.split("?key=").join("")
              : "...",
          first: 10,
        },
      })
    }
  }, [])

  const Pagination = ({ hasPreviousPage, hasNextPage, prevPage, nextPage }) => {
    if (hasNextPage || hasPreviousPage) {
      return (
        <div className="d-flex justify-content-end">
          <div className="paginate">
            <button
              className="btn btn-primary pagination-button"
              disabled={!hasPreviousPage}
              onClick={prevPage}
            >
              <i className="fas fa-chevron-left" />
            </button>
            <button
              className="btn btn-primary pagination-button"
              disabled={!hasNextPage}
              onClick={nextPage}
            >
              <i className="fas fa-chevron-right" />
            </button>
          </div>
        </div>
      )
    } else {
      return <div className="pagination"></div>
    }
  }

  const hasPreviousPage = () => {
    if (searchResult) {
      return searchResult.contentNodes.pageInfo.hasPreviousPage
    } else {
      return false
    }
  }

  const hasNextPage = () => {
    if (searchResult) {
      return searchResult.contentNodes.pageInfo.hasNextPage
    } else {
      return false
    }
  }

  const prevCursor = () => {
    if (searchResult) {
      return searchResult.contentNodes.pageInfo.startCursor
    } else {
      return ""
    }
  }

  const nextCursor = () => {
    if (searchResult) {
      return searchResult.contentNodes.pageInfo.endCursor
    } else {
      return ""
    }
  }

  const nextPage = cursor => {
    if (searchResult) {
      getResult({
        variables: {
          key:
            location.search.split("?key=").length > 1
              ? location.search.split("?key=").join("")
              : "...",
          first: 10,
          after: `${cursor}`,
        },
      })
    }
  }

  const previousPage = cursor => {
    if (searchResult) {
      getResult({
        variables: {
          key:
            location.search.split("?key=").length > 1
              ? location.search.split("?key=").join("")
              : "...",
          last: 10,
          before: `${cursor}`,
        },
      })
    }
  }

  const result = searchResult && searchResult.contentNodes.edges
  return (
    <Layout>
      <Section className="bg-light">
        <Container>
          <p className="text-muted">
            {result !== undefined && result.length > 0
              ? `${result.length} ${
                  lang == "EN" ? "result found" : "hasil ditemukan"
                }`
              : ""}
          </p>
          <form>
            <div className="input-group mb-3">
              <div className="input-group-prepend">
                <span className="input-group-text" id="basic-addon1">
                  <img src={icSearch} className="img-fluid" alt="search" />
                </span>
              </div>
              <input
                type="text"
                name="key"
                id="key"
                className="form-control"
                placeholder={lang == "EN" ? "Search" : "Cari"}
                aria-label="Username"
                aria-describedby="basic-addon1"
              />
            </div>
          </form>
          {loading && (
            <div className="result-loader">
              <i class="fas fa-spinner fa-spin"></i>
            </div>
          )}
          {!loading && (
            <p className="text-black font-weight-bold">
              {result === undefined ||
                (result.length === 0 &&
                  `${
                    lang == "EN"
                      ? "404 results not found"
                      : "404 hasil tidak ditemukan"
                  }`)}
            </p>
          )}
          <div className="row"></div>
          {result &&
            !loading &&
            result.map((item, i) => {
              if (item.node.__typename === "Post") {
                return (
                  <div className="col-12 mb-3">
                    <Card
                      label={lang == "EN" ? "News" : "Berita"}
                      labelClassName="mb-2"
                      title={
                        lang === "EN"
                          ? item?.node?.title
                          : item?.node?.translation?.title || item?.node?.title
                      }
                      text={
                        lang === "EN"
                          ? item.node.excerpt
                          : item?.node?.translation?.excerpt ||
                            item?.node?.title
                      }
                      link={`${lang === "EN" ? "" : "/id"}/news-events/${item.node.slug}`}
                      target="#"
                      textButton={
                        lang == "EN" ? "Learn More" : "Pelajari Lebih Lanjut"
                      }
                      className="text-limit"
                    />
                  </div>
                )
              } else if (item.node.__typename === "Project") {
                const textBlockEn = item.node.blocks.find(
                  x => x.__typename === "AcfColHeadlineBlock"
                )
                const textBlockId = item?.node?.translation?.blocks.find(
                  x => x.__typename === "AcfColHeadlineBlock"
                )

                const text =
                  lang === "EN"
                    ? textBlockEn
                    : (textBlockId && textBlockId) || textBlockEn
                return (
                  <div className="col-12 mb-3">
                    <Card
                      label={lang == "EN" ? "Development" : "Pengembangan"}
                      labelClassName="mb-2"
                      title={
                        lang === "EN"
                          ? item?.node?.title
                          : item?.node?.translation?.title || item?.node?.title
                      }
                      text={text?.colHeadline?.text}
                      link={`/development/${item?.node?.propertyTypes?.nodes[0]?.slug}/${item?.node?.slug}`}
                      target="#"
                      textButton={
                        lang == "EN" ? "Learn More" : "Pelajari Lebih Lanjut"
                      }
                      className="text-limit"
                    />
                  </div>
                )
              } else if (item.node.__typename === "Page") {
                return (
                  <div className="col-12 mb-3">
                    <Card
                      label={lang == "EN" ? "Page" : "Halaman"}
                      labelClassName="mb-2"
                      title={
                        lang === "EN"
                          ? item?.node?.title
                          : item?.node?.translation?.title || item?.node?.title
                      }
                      text="Lorem Ipsum"
                      link={`/${item.node.slug}`}
                      target="#"
                      className="text-limit"
                      textButton={
                        lang == "EN" ? "Learn More" : "Pelajari Lebih Lanjut"
                      }
                    />
                  </div>
                )
              } else if (item.node.__typename === "Facility") {
                return (
                  <div className="col-12 mb-3">
                    <Card
                      label={lang == "EN" ? "Facilities" : "Fasilitas"}
                      labelClassName="mb-2"
                      title={
                        lang === "EN"
                          ? item?.node?.title
                          : item?.node?.translation?.title || item?.node?.title
                      }
                      text="Lorem Ipsum"
                      link={`facilities/${item.node.facility_categories.nodes[0].slug}`}
                      target="#"
                      className="text-limit"
                      textButton={
                        lang == "EN" ? "Learn More" : "Pelajari Lebih Lanjut"
                      }
                    />
                  </div>
                )
              }
            })}
          <Pagination
            hasPreviousPage={hasPreviousPage()}
            hasNextPage={hasNextPage()}
            prevPage={() => previousPage(prevCursor())}
            nextPage={() => nextPage(nextCursor())}
          />
        </Container>
      </Section>
    </Layout>
  )
}

export default SearchResult
